import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import getTheme from "theme";
import CssBaseline from "@mui/material/CssBaseline";

import Paper from "@mui/material/Paper";
import { useDarkMode } from "components/Page";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
	Home,
	AIEngineersPage,
	AutomatedAISolutionsPage,
	CollaborationPage,
	DataAnalysisPage,
	EndToEndArchitechturePage,
	PrivateDeploymentPage,
	ProductDevelopmentPage,
	StrategyConsultingPage,
	ContactPage,
	ProjectsPage,
} from "./pages";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import TermsOfService from "./pages/TermsOfService";
import ProjectDetail from "./pages/ProjectDetail";
import CookieConsent from "./components/CookieConsent";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Main } from "./layouts";

const routes = [
	{ path: "/", component: <Home /> },
	{ path: "/AiEngineers", component: <AIEngineersPage /> },
	{ path: "/automated-ai-solutions", component: <AutomatedAISolutionsPage /> },
	{ path: "/collaboration", component: <CollaborationPage /> },
	{ path: "/data-analysis", component: <DataAnalysisPage /> },
	{
		path: "/end-to-end-architecture",
		component: <EndToEndArchitechturePage />,
	},
	{ path: "/private-deployment", component: <PrivateDeploymentPage /> },
	{ path: "/product-development", component: <ProductDevelopmentPage /> },
	{ path: "/strategy-consulting", component: <StrategyConsultingPage /> },
	{ path: "/contact", component: <ContactPage /> },
	{ path: "/projects", component: <ProjectsPage /> },
	{ path: "/project/:projectId", component: <ProjectDetail /> },
	{ path: "/privacy-policy", component: <PrivacyPolicy /> },
	{ path: "/cookie-policy", component: <CookiePolicy /> },
	{ path: "/terms-of-service", component: <TermsOfService /> },
];

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0); // Scrolls to the top-left corner
	}, [pathname]); // Runs every time the route changes

	return null; // This component doesn't render anything
};

const ScrollToHash = () => {
	const { hash } = useLocation();

	useEffect(() => {
		if (hash) {
			const id = hash.replace("#", "");
			const element = document.getElementById(id);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [hash]);

	return null;
};

function App() {
	const [themeMode, themeToggler] = useDarkMode();

	return (
		<BrowserRouter>
			<ThemeProvider theme={getTheme(themeMode, themeToggler)}>
				<CssBaseline />
				<ScrollToTop />
				<ScrollToHash />
				<Main>
					<Routes>
						{routes.map((route) => (
							<Route
								key={route.path}
								path={route.path}
								element={route.component}
							/>
						))}
					</Routes>
				</Main>

				<Paper elevation={0}></Paper>
				<CookieConsent />
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;