import { useState } from "react";
import {
	Box,
	Grid,
	Typography,
	Paper,
	Container,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import heroBg from "images/aiPhotos/23.jpg";
import project1 from "images/projects/iss/Thumbnail_ISS.jpg";
import project2 from "images/projects/pathfinder/Thumbnail_Pathfinder.jpg";
import issBg from "images/projects/iss/issBg.jpg";
import pathfinderBg from "images/projects/pathfinder/pathfinderBg.jpg";
import previewPathinder from "images/projects/pathfinder/preview.jpg";
import previewIss from "images/projects/iss/preview.jpg";

const projects = [
	{
		index: 0,
		title: "Pathfinder",
		description: [
			`At Pillar1, we developed the prototype for International Social Service Archive Digitization project, which aimed to build a comprehensive digital archive system that turned dormant social work documents into fully searchable, accessible, and valuable data. By digitizing large volumes of case files, guidelines, and regulatory documents, we streamlined the processes of classification, search, and secure management. Through scanning, splitting, and advanced AI-driven OCR, we established consistent metadata fields and enabled swift retrieval of relevant information. Summaries offered quick overviews of complex documents, drastically reducing the need for manual research and improving organizational efficiency.`,
			`This flexible platform supported the transformation of historical archives into actionable data for policy development, evidence-based interventions, and regional collaboration, the system maximized the value of previously underused information. Over time, it will allow research teams to analyze critical trends and insights across multiple countries and social work domains.`,
			`The project delivered a prototype for a user-friendly interface with advanced data classification and secure document management capabilities for social service professionals and researchers to effectively manage, analyze, and safeguard essential social work information.`,
		],
		thumbnail: project2,
		background: pathfinderBg,
		preview: previewPathinder,
		whitepaper: "/whitepapers/whitepaper1.pdf",
		whitepaperName: "Digital Archive Systems: The Future of Document Management",
	},
	{
		index: 1,
		title: "ISS",
		description: [
			`At Pillar1, we have developed the prototype of an AI-assisted social case management system that re-engineers traditional social work processes for improved speed, quality, and consistency. By integrating domain knowledge with powerful foundation models, our system transforms manual, time-consuming workflows into dynamic, data-driven operations. The system is grounded in user domain specific guidelines for diverse social services—such as child protection, family reunification, and refugee assistance—offering comprehensive support to caseworkers, managers, and organization directors. Through embedded AI capabilities, critical case information is analyzed to identify immediate risks, plan interventions, and coordinate across agencies. The result is streamlined decision-making, better time management, and proactive planning that places client safety and success at the forefront.`,
			`Additionally, all stakeholders gain real-time insights, from case summaries to performance evaluations, ensuring higher team performance and greater process adherence. By centralizing data and leveraging advanced AI analysis, the Pillar1 solution delivers consistent recommendations, fosters user-friendly communication, and guarantees continuity in care. Its feedback loop ensures continuous improvement as insights gleaned from completed cases inform best practices going forward. With Pillar1's system, organizations are empowered to enhance efficiency, quality, and accountability while achieving optimal client outcomes for social work around the globe. This solution sets new benchmarks in AI-driven social case management excellence.`,
		],
		thumbnail: project1,
		background: issBg,
		preview: previewIss,
		whitepaper: "/whitepapers/whitepaper2.pdf",
		whitepaperName: "AI-Assisted Case Management: Transforming Social Services",
	},
	{
		index: 2,
		title: "Document comprehension",
		description: [
			`At Pillar1, we created an AI-assisted document comprehension system that transforms uploaded PDFs into searchable text through OCR. Users receive alerts when documents are ready, then interact with a language model to annotate and ask questions. By highlighting sections and storing all queries and answers, our platform ensures a robust record of each interaction. A unique URL retains all document context for future reference, streamlining the review of contracts, policies, or legal documents. This comprehensive approach simplifies complex text analysis, empowers users with actionable insights, and maintains a dynamic, accessible archive of knowledge for ongoing document engagement. Interactions are stored.`,
		],
		thumbnail: project1,
		background: issBg,
		preview: previewIss,
		whitepaper: "/whitepapers/whitepaper3.pdf",
		whitepaperName: "Intelligent Document Comprehension with AI",
	},
];

const ProjectCard = ({ title, description, thumbnail, whitepaper, whitepaperName, handleClick }) => {
	const theme = useTheme();
	
	// Extract the first sentence from description for the summary
	const summary = description[0].split('.')[0] + '.';

	return (
		<Box
			component={Paper}
			onClick={handleClick}
			sx={{
				position: "relative",
				width: { xs: 300, md: 350, lg: 380 },
				height: "100%", // Ensure all cards have the same height
				overflow: "hidden",
				cursor: "pointer",
				borderRadius: 2,
				boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
				transition: "transform 0.3s ease, box-shadow 0.3s ease",
				"&:hover": {
					transform: "translateY(-8px)",
					boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
				},
				display: "flex",
				flexDirection: "column",
				backgroundColor: theme.palette.mode === "dark" ? "rgba(30, 30, 30, 0.9)" : "white",
			}}
		>
			{/* Image Section */}
			<Box
				sx={{
					position: "relative",
					height: 220,
					width: "100%",
					overflow: "hidden",
				}}
			>
				<Box
					sx={{
						backgroundImage: `url(${thumbnail})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						height: "100%",
						width: "100%",
						transition: "transform 0.5s ease",
						"&:hover": {
							transform: "scale(1.05)",
						},
					}}
				/>
				<Box
					sx={{
						position: "absolute",
						top: 16,
						left: 16,
						backgroundColor: theme.palette.primary.main,
						color: "white",
						px: 2,
						py: 0.75,
						borderRadius: 1,
						fontSize: "0.75rem",
						fontWeight: 600,
						textTransform: "uppercase",
						letterSpacing: 0.5,
					}}
				>
					AI Solution
				</Box>
			</Box>

			{/* Content Section */}
			<Box
				sx={{
					p: 3,
					display: "flex",
					flexDirection: "column",
					flexGrow: 1,
				}}
			>
				{/* Title */}
				<Typography
					variant="h5"
					sx={{
						fontWeight: 700,
						mb: 2,
						color: theme.palette.text.primary,
					}}
				>
					{title}
				</Typography>

				{/* Brief Description */}
				<Typography
					variant="body2"
					sx={{
						color: theme.palette.text.secondary,
						mb: 3,
						lineHeight: 1.6,
						flexGrow: 1,
					}}
				>
					{summary}
				</Typography>

				{/* Card Footer */}
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mt: "auto",
						pt: 2,
						borderTop: `1px solid ${theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.06)"}`,
					}}
				>
					<Box>
						{whitepaper && (
							<a 
								href={whitepaper} 
								target="_blank" 
								rel="noopener noreferrer"
								onClick={(e) => {
									e.stopPropagation();
								}}
								style={{
									color: theme.palette.primary.main,
									fontSize: "0.75rem",
									fontWeight: 500,
									textDecoration: "none",
								}}
							>
								Download White Paper
							</a>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							color: theme.palette.primary.main,
							fontSize: "0.875rem",
							fontWeight: 600,
						}}
					>
						View Details
					</Box>
				</Box>
			</Box>
		</Box>
	);
};


const ProjectsPage = () => {
	const theme = useTheme();

	const handleClick = (project) => {
		// Navigate to project details page with the project index
		window.location.href = `/project/${project.index}`;
	};

	return (
		<Box>
			{/* Hero Section */}
			<Box
				sx={{
					position: "relative",
					py: { xs: 8, md: 12 },
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85)), url(${heroBg})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundAttachment: "fixed",
				}}
			>
				<Container maxWidth="lg">
					<Grid
						container
						spacing={4}
						alignItems="center"
					>
						<Grid
							item
							xs={12}
							md={7}
						>
							<Typography
								variant="h2"
								component="h1"
								sx={{
									fontWeight: 700,
									fontSize: { xs: "2.5rem", sm: "3.5rem" },
									color: "rgba(255, 255, 255, 1)",

									mb: 3,
								}}
							>
								Empowering Businesses Through Innovation
							</Typography>

							<Typography
								variant="h6"
								sx={{
									color: "rgba(255, 255, 255, 0.85)",
									mb: 4,
									lineHeight: 1.6,
									maxWidth: "90%",
								}}
							>
								Discover how Pillars AI has transformed challenges into
								opportunities for our clients, delivering impactful AI solutions
								that drive measurable success.
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			{/* Portfolio Section */}
			<Box sx={{ py: { xs: 8, md: 12 }, backgroundColor: theme.palette.mode === "dark" ? "rgba(20, 20, 20, 0.8)" : "#f8f9fa" }}>
				<Container maxWidth="lg">
					<Box sx={{ mb: 8, textAlign: "center" }}>
						<Typography
							variant="h3"
							component="h2"
							sx={{
								fontWeight: 700,
								mb: 3,
								color: theme.palette.text.primary,
							}}
						>
							Our Portfolio
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: theme.palette.text.secondary,
								maxWidth: 650,
								mx: "auto",
								lineHeight: 1.6,
							}}
						>
							Explore our innovative AI solutions designed to solve real-world business challenges
						</Typography>
					</Box>

					<Grid container spacing={5} justifyContent="center" sx={{ mb: 3 }}>
						{projects.map((project) => (
							<Grid item xs={12} sm={6} lg={4} key={project.index} sx={{ display: 'flex' }}>
								<ProjectCard
									{...project}
									handleClick={() => handleClick(project)}
								/>
							</Grid>
						))}
					</Grid>
				</Container>
			</Box>

		</Box>
	);
};

export default ProjectsPage;
