import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Container,
  Paper,
  IconButton,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GetAppIcon from "@mui/icons-material/GetApp";
import project1 from "images/projects/iss/Thumbnail_ISS.jpg";
import project2 from "images/projects/pathfinder/Thumbnail_Pathfinder.jpg";
import issBg from "images/projects/iss/issBg.jpg";
import pathfinderBg from "images/projects/pathfinder/pathfinderBg.jpg";
import previewPathinder from "images/projects/pathfinder/preview.jpg";
import previewIss from "images/projects/iss/preview.jpg";

// This should match the projects data in Projects.js
const projects = [
  {
    index: 0,
    title: "Pathfinder",
    description: [
      `At Pillar1, we developed the prototype for International Social Service Archive Digitization project, which aimed to build a comprehensive digital archive system that turned dormant social work documents into fully searchable, accessible, and valuable data. By digitizing large volumes of case files, guidelines, and regulatory documents, we streamlined the processes of classification, search, and secure management. Through scanning, splitting, and advanced AI-driven OCR, we established consistent metadata fields and enabled swift retrieval of relevant information. Summaries offered quick overviews of complex documents, drastically reducing the need for manual research and improving organizational efficiency.`,
      `This flexible platform supported the transformation of historical archives into actionable data for policy development, evidence-based interventions, and regional collaboration, the system maximized the value of previously underused information. Over time, it will allow research teams to analyze critical trends and insights across multiple countries and social work domains.`,
      `The project delivered a prototype for a user-friendly interface with advanced data classification and secure document management capabilities for social service professionals and researchers to effectively manage, analyze, and safeguard essential social work information.`,
    ],
    thumbnail: project2,
    background: pathfinderBg,
    preview: previewPathinder,
    whitepaper: "/whitepapers/whitepaper1.pdf",
    whitepaperName: "Digital Archive Systems: The Future of Document Management",
  },
  {
    index: 1,
    title: "ISS",
    description: [
      `At Pillar1, we have developed the prototype of an AI-assisted social case management system that re-engineers traditional social work processes for improved speed, quality, and consistency. By integrating domain knowledge with powerful foundation models, our system transforms manual, time-consuming workflows into dynamic, data-driven operations. The system is grounded in user domain specific guidelines for diverse social services—such as child protection, family reunification, and refugee assistance—offering comprehensive support to caseworkers, managers, and organization directors. Through embedded AI capabilities, critical case information is analyzed to identify immediate risks, plan interventions, and coordinate across agencies. The result is streamlined decision-making, better time management, and proactive planning that places client safety and success at the forefront.`,
      `Additionally, all stakeholders gain real-time insights, from case summaries to performance evaluations, ensuring higher team performance and greater process adherence. By centralizing data and leveraging advanced AI analysis, the Pillar1 solution delivers consistent recommendations, fosters user-friendly communication, and guarantees continuity in care. Its feedback loop ensures continuous improvement as insights gleaned from completed cases inform best practices going forward. With Pillar1's system, organizations are empowered to enhance efficiency, quality, and accountability while achieving optimal client outcomes for social work around the globe. This solution sets new benchmarks in AI-driven social case management excellence.`,
    ],
    thumbnail: project1,
    background: issBg,
    preview: previewIss,
    whitepaper: "/whitepapers/whitepaper2.pdf",
    whitepaperName: "AI-Assisted Case Management: Transforming Social Services",
  },
  {
    index: 2,
    title: "Document comprehension",
    description: [
      `At Pillar1, we created an AI-assisted document comprehension system that transforms uploaded PDFs into searchable text through OCR. Users receive alerts when documents are ready, then interact with a language model to annotate and ask questions. By highlighting sections and storing all queries and answers, our platform ensures a robust record of each interaction. A unique URL retains all document context for future reference, streamlining the review of contracts, policies, or legal documents. This comprehensive approach simplifies complex text analysis, empowers users with actionable insights, and maintains a dynamic, accessible archive of knowledge for ongoing document engagement. Interactions are stored.`,
    ],
    thumbnail: project1,
    background: issBg,
    preview: previewIss,
    whitepaper: "/whitepapers/whitepaper3.pdf",
    whitepaperName: "Intelligent Document Comprehension with AI",
  },
];

const ProjectDetail = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [project, setProject] = useState(null);

  useEffect(() => {
    // Find the project by ID from the projects array
    const projectIndex = parseInt(projectId);
    const foundProject = projects.find((p) => p.index === projectIndex);
    
    if (foundProject) {
      setProject(foundProject);
    } else {
      // Redirect to projects page if project not found
      navigate("/projects");
    }
  }, [projectId, navigate]);

  if (!project) {
    return <Box sx={{ p: 4, textAlign: "center" }}>Loading...</Box>;
  }

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          minHeight: 300,
          background: `linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85)), url(${project.background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
          py: 8,
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <IconButton 
            onClick={() => navigate("/projects")}
            sx={{ 
              position: "absolute", 
              top: 20, 
              left: { xs: 20, md: 40 },
              bgcolor: theme.palette.primary.main,
              color: "white",
              "&:hover": {
                bgcolor: theme.palette.primary.dark,
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "white",
                fontWeight: 500,
                px: 2,
                py: 0.75,
                backgroundColor: theme.palette.primary.main,
                textTransform: "uppercase",
                fontSize: { xs: ".875rem", sm: "1rem" },
                borderRadius: 1,
                letterSpacing: 1,
                display: "inline-block",
                mb: 3,
              }}
            >
              Project Case Study
            </Typography>
            
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: "2rem", sm: "2.5rem", md: "3rem" },
                color: "white",
                fontWeight: 700,
                textAlign: "center",
                mb: 3,
              }}
            >
              {project.title}
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ py: 8 }}>
        <Grid container spacing={6}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
                px: 2,
                py: 0.75,
                backgroundColor: theme.palette.primary.main,
                textTransform: "uppercase",
                fontSize: { xs: ".75rem", sm: ".875rem" },
                borderRadius: 1,
                width: "fit-content",
                letterSpacing: 0.5,
              }}
            >
              Project Overview
            </Typography>

            <Box>
              {project.description.map((desc, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  color="text.primary"
                  sx={{ mb: 2, lineHeight: 1.7 }}
                >
                  {desc}
                </Typography>
              ))}
            </Box>

            {project.whitepaper && (
              <Box sx={{ mt: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  component="a"
                  href={project.whitepaper}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<GetAppIcon />}
                  sx={{ 
                    py: 1.5, 
                    px: 3,
                    borderRadius: 2,
                    textTransform: 'none',
                    fontWeight: 600
                  }}
                >
                  Download White Paper: {project.whitepaperName}
                </Button>
              </Box>
            )}

          </Grid>

          <Grid
            item
            xs={12}
            md={4}
          >
            <Paper
              elevation={2}
              sx={{
                borderRadius: 2,
                overflow: "hidden",
                height: "100%",
                minHeight: 300,
                position: "sticky",
                top: 100,
              }}
            >
              <img
                src={project.preview}
                alt={project.title}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Paper>
          </Grid>
        </Grid>
        
        <Box sx={{ mt: 8, textAlign: "center" }}>
          <Button 
            variant="contained" 
            size="large"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/projects")}
          >
            Back to Projects
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ProjectDetail;